@use '../' as *;
.fade.modal.show {
    @include flex(center,center);
    display: flex !important;
}

.modal-content {
    .modal-header {
        height: 0;
        padding: 0;
        border: none;
        .btn-close {
            position: absolute;
            background: none;
            overflow: hidden;
            height: 30px;
            width: 30px;
            max-width: 30px;
            background-color: var(--primary-color3);
            border-radius: 50%;
            padding: 0;
            margin: 0;
            z-index: 99;
            opacity: 1;
            right: -15px;
            top: -15px;
            @include flex(center,center);
            border: none;
            &::after {
                content: "x";
                font-size: 15px;
                color: #fff;
                line-height: 0;
                font-weight: bold;
            }
        }
    }
}


.popup-video {
    position: fixed;
    z-index: 1055;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    outline: none !important;
    @include flex(center,center);
    .overlay {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1042;
        overflow: hidden;
        position: fixed;
        background: #3f3f3f;
        opacity: 0.8;
        filter: alpha(opacity=80);
        position: absolute;
        z-index: 1;
    }
}

.popup-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 15px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    @include flex(center,center);   
    iframe {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: 0 0 8px rgb(0 0 0 / 60%);
        background: black;
    }
}

.popup-inner-content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: 1045;
    cursor: auto;
    line-height: 0;
    width: 100%;
    max-width: 900px;
    @include flex(center,center);
}

.mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 56.25%;
    @include flex(center,center);
}

.popup-inner .close-btn {
    @include transition3;
    color: white;
    background: transparent;
    right: -6px;
    text-align: right;
    padding-right: 6px;
    width: 100%;
    right: -20px;
    top: -60px;
    height: 44px;
    line-height: 44px;
    position: absolute;
    text-decoration: none;
    text-align: right;
    opacity: 0.65;
    color: white;
    font-style: normal;
    font-size: 20px;
    border: none;
    outline: none;
    overflow: visible;
    &:hover {
        opacity: 1;
    }
}