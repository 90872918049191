@use './../../scss/' as *;

.left-bottom-wrapper {
    position: fixed;
    bottom: 2rem;
    left: 3rem;

    margin-right: auto;
    padding: 0;
    width: 350px;
    height: auto;

    @media screen and (max-width: 768px) {
        bottom: 0;
        left: 0;
        padding: 0.5rem;
        width: 100%;
        
    }

}