@use '../' as *;


/* 
   * Button 
   * page title
   * card article 
   * Help
   * Swiper button
   * Slider
   * blog page
   * flat cetagories
   * flat auctions
   * flat top seller
   * flat explore
   * flat friendly
   * flat collection
   * flat brand 
   * flat featured
   * dropdown
   * live auctions details
   * flat about
   * flat counter
   * flat video
   * flat author profile
   * flat edit profile
   * flat create item
   * flat login
   * flat register
   * flat connect wallet
   * flat faq
   * flat map
   * flat contact
   * modal Popup
   * switch mode 
*/
* {
    margin: 0;
    padding: 0;
}

.themes-container {
    position     : relative;
    margin-left  : auto;
    margin-right : auto;
    padding-right: 15px;
    padding-left : 15px;
    max-width        : 1550px;
    width    : 100%;
    box-sizing: border-box;
}

.container {
    max-width        : 1200px;
    margin-left  : auto;
    margin-right : auto;
    padding-right: 15px;
    padding-left : 15px;
}

.row {
    margin-left: -15px;
    margin-right: -15px;
}

.themes-container2 {
    position     : relative;
    margin-left  : auto;
    margin-right : auto;
    padding-right: 15px;
    padding-left : 15px;
    width: 1520px;
    max-width    : 100%;
    box-sizing: border-box;
}

.themes-container3 {
    position     : relative;
    margin-left  : auto;
    margin-right : auto;
    padding-right: 15px;
    padding-left : 15px;
    width        : 1170px;
    max-width    : 100%;
    box-sizing: border-box;
}

.tf-section {
    padding: 120px 0;
    @include transition3;
}

/* Page Title Inner
-------------------------------------------------------------- */
.flat-title-page {
    &.inner {
        padding: 310px 0 222px;
        position       : relative;
        background: linear-gradient(79.57deg, rgba(19, 5, 24, 0.07) 10.75%, rgba(119, 89, 243, 0.07) 53.13%, rgba(19, 5, 24, 0.07) 106.55%);
    }
}

.is_dark {
    .flat-title-page {
        &.slider,
        &.inner {
            background: linear-gradient(79.57deg, rgba(31, 17, 85, 0) 10.75%, #130518 50.17%, rgba(31, 17, 85, 0) 106.55%);
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            background-color: rgba(119, 89, 243, 0.1);
        }
    }
}

.page-title-heading .heading {
    line-height: 87px;
    color      : var(--primary-color2);
    margin-left: -3px;
}

.mark-page {
    top     : 0;
    right   : -6px;   
}

.mark-page2 {
    left    : 3.7%;
    bottom  : 38.3%;
}

.mark-page3 {
    left: 38.8%;
    top: 22.6%;
    animation: move3 8s infinite linear;
}

.mark-page4 {
    z-index: 9;
    right: 9%;
    bottom: 24%;
    animation: move5 8s infinite linear;
}

.mark-page5 {
    //top     : 10px;
    //left   : 2px;
}

.sc-btn-top .sc-button {
    padding: 18.5px 31.5px;
}

/* sc card article 
--------------------------------------------------*/
.sc-card-article {
    &.blog-article {
        background: #fff;
        border: 1px solid rgba(19, 5, 24, 0.07);
        box-sizing: border-box;
        padding           : 20px 19px 29px;
        border-radius     : 5px;
        margin-top    : 1.5px;
        margin-bottom    : -2px;
        -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        transition        : all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        &:hover {
            -webkit-transform: translateY(-5px);
            transform        : translateY(-5px);
            box-shadow: 1px -3px 16px rgb(47 83 109 / 12%);
        }
        &.style-article {
            &:hover {
                -webkit-transform: translateY(0px);
                transform        : translateY(0px);
                box-shadow: none;
            }
        } 
    }
}

.is_dark {
    .sc-card-article {
        &.blog-article {
            background: rgba(255, 255, 255, 0.03);
        }
    }
}

.sc-card-article {  
        .card-media{
            border-radius: 8px;
            overflow     : hidden;
            img {
                width             : 100%;
                object-fit        : cover;
                -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
                transition        : all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
                border-radius: 5px;
                user-select: none;
            }
        } 
        .post {
            padding: 27px 10px 0;
            .text-article {
                margin-bottom: 17px;
                h3 {
                    text-transform: capitalize;
                    margin-bottom : 18px;
                }
            }
        }        
}

.meta-info {
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    .author {
        display        : flex;
        align-items    : center;
        justify-content: space-between;
        
        .avatar {
            width        : 35px;
            height       : 35px;
            border-radius: 50%;
            margin-right : 8.5px;
            position: relative;
            flex-shrink  : 0;  
            img {
                border-radius: 50%; 
            }
            &::before {
                border-radius: 50%;
                transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 35px;
                height: 35px; 
                display: inline-block;
                border-top: 1px solid transparent;
                border-left: 1px solid #130518;
                border-right: 1px solid #130518;
                border-bottom: 1px solid #130518;
                transform: rotate(45deg);     
            }
            // countdown home 01
            .check {
                position: absolute;
                bottom: 5px;
                right: -6px;
            }               
        }
        .info {
            h5 {
                line-height: 20px;
                margin-top: 1px;
                color      : var(--primary-color4);
                font-family: 'Open Sans';
                font-weight: 600;
                a {
                    margin-left: 3px;
                }
            }
            .date {
                color      : var(--primary-color4);
                font-size  : 13px;
                line-height: 20px;
                font-weight: 500;
                letter-spacing: -0.1px;
                span {
                    color  : var(--primary-color8);
                }
            }
        }
    }
} 

.is_dark .meta-info .author .info .date span {
    font-weight: 400;
}
.meta-info.style .author .avatar::before,
.is_dark .meta-info .author .avatar::before {
    display: none;
}

/* help */
.flat-help {
    background: linear-gradient(79.57deg, rgba(19, 5, 24, 0.07) 10.75%, rgba(119, 89, 243, 0.07) 53.13%, rgba(19, 5, 24, 0.07) 106.55%);
    padding-top: 120px;
    .title-help {
        padding: 0 38.5px;
        position: relative;
        &::after {
            content: '';
            position: absolute;
            width: 10px;
            height: 70px;
            left: 0;
            top: 3px;
            background-color:  var(--primary-color3);
            border-radius: 5px;
        }
        h2 {
            line-height: 38.7px;
            margin-top: -3px;
        }
    }
    .help-row {
        padding-bottom: 56px; 
        border-bottom: 1px solid var(--primary-color6);
        position: relative;
    }
    &.page-style {
        background: unset;
    }
}

.flat-help {
    &.home2 {
        background : unset;
        padding-top: 0px;
        .style-container {
            padding-top: 59px;
            padding-bottom: 55px;
            border-radius: 10px;
            background: linear-gradient(79.57deg, rgba(102, 38, 124, 0.07) 10.75%, rgba(119, 89, 243, 0.07) 53.13%, rgba(19, 5, 24, 0.07) 106.55%);
        }
        .title-help {
            margin-left: 35px;
        }
        .widget-subcribe {
            padding-right: 35px;
            margin-left: -2px;
        }
    }
    &.home3 {
        .style-container {
            border: 1px solid var(--primary-color6);
            background: var(--primary-color10);
        }
    }
    &.page {
        background : unset;
    }
}

.is_dark {
    .flat-help {
        &.page-style {
            background: linear-gradient(79.57deg, rgba(19, 5, 24, 0.07) 10.75%, rgba(119, 89, 243, 0.07) 53.13%, rgba(19, 5, 24, 0.07) 106.55%);
        }
    }
}

/* swiper button */
.swiper-button-next.btn-slide-next::after {
    content: "\f178";
}

 .swiper-button-prev.btn-slide-prev::after {
    content: "\f177";
}

 .swiper-button-prev.btn-slide-prev.active::after,
.swiper-button-next.btn-slide-next.active::after,
 .swiper-button-prev.btn-slide-prev:hover:after,
 .swiper-button-next.btn-slide-next:hover:after{
    color: #fff;
}
.swiper-button-prev.btn-slide-prev.active,
.swiper-button-next.btn-slide-next.active,
.swiper-button-next.btn-slide-next:hover,
.swiper-button-prev.btn-slide-prev:hover{
    background: var(--primary-color3);
    border: 1px solid var(--primary-color3);
}

.is_dark .swiper-button-prev.btn-slide-prev.active,
.is_dark .swiper-button-next.btn-slide-next.active,
.is_dark .swiper-button-next.btn-slide-next:hover,
.is_dark .swiper-button-prev.btn-slide-prev:hover {
    background: var(--primary-color3);
    border-color: var(--primary-color3);
}

.swiper-button-prev.btn-slide-prev.active::after,
.swiper-button-next.btn-slide-next.active::after,
.swiper-button-prev.btn-slide-prev:hover:after,
 .swiper-button-next.btn-slide-next:hover:after{
    color: #fff;
}

.swiper-button-next.btn-slide-next,
.swiper-button-prev.btn-slide-prev{
    width: 80px;
    height: 40px;
    border-radius: 5px;
    box-shadow: 0px 3px 16px rgb(47 83 109 / 12%);
    -webkit-transition: all 0.3s ease-in-out;
    background: #fff;
    border: 1px solid var(--primary-color6);
}

.is_dark .swiper-button-next.btn-slide-next ,
.is_dark .swiper-button-prev.btn-slide-prev {
    background: #FFFFFF08;
    border-color:  #FFFFFF33;
}

 .swiper-button-next.btn-slide-next::after, 
 .swiper-button-prev.btn-slide-prev::after {
    font-family: "Font Awesome 5 Pro";
    color: var(--primary-color3);
    font-weight: 300;
    font-size: 28px;
    opacity: 1;
}

.is_dark .swiper-button-next.btn-slide-next:after ,
.is_dark .swiper-button-prev.btn-slide-prev:after {
    color: #fff;
}

.flat-auctions.dark-style .swiper-button-next.btn-slide-next,
.swiper-button-next.btn-slide-next {
    right: 0px;
    top: 98%;
}

 .swiper-button-prev.btn-slide-prev {
    right: 100px;
    top: 98%;
    left: auto;
}

.flat-auctions.dark-style  .swiper-button-next.btn-slide-next {
    right: 15px;
    top: 97%;
}

.flat-auctions.dark-style .swiper-button-prev.btn-slide-prev {
    right: 115px;
    top: 97%;
}

.flat-collection.dark-style .swiper-button-next.btn-slide-next,
.flat-collection.dark-style .swiper-button-prev.btn-slide-prev {
    top: 96.5%;
}

.flat-top-seller.dark-style .swiper-button-next.btn-slide-next,
.flat-top-seller.dark-style .swiper-button-prev.btn-slide-prev {
    top: 95.5%;
}

.flat-auctions .swiper-button-next.btn-slide-next {
    right: 15px;
}

.flat-auctions.swiper-button-prev.btn-slide-prev {
    right: 115px; 
}

.flat-auctions .swiper-button-prev.btn-slide-prev,
.flat-auctions .swiper-button-next.btn-slide-next {
    top: 22px;  
}

.flat-collection .swiper-button-next.btn-slide-next,
.flat-collection .swiper-button-prev.btn-slide-prev {
    top: 25px;
}

.slider-style {
    margin-bottom: 30px;
}

// swiper slider home

.slider .btn-slide-next,
.slider .btn-slide-prev {
    width: 60px;
    height: 60px;
    border-radius: 50%; 
    top  : 50%;
    z-index: 99;
    opacity: 0;
    @include transition3;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--primary-color3);
    position: absolute;
    i {
        font-size: 28px;
        font-weight: 300;
        color: #fff;
    }
}

.slider.slider2  .btn-slide-next,
.slider.slider2  .btn-slide-prev {
    top  : 40%;
}

.slider .btn-slide-next {
    right: 30px; 
    left : auto; 
}
.slider  .btn-slide-prev {
    right: unset; 
    left : 30px; 
}

.slider:hover .btn-slide-next,
.slider:hover .btn-slide-prev {
    opacity: 1;
    @include transition3;
}

.slider .btn-slide-next.swiper-button-disabled,
.slider .btn-slide-prev.swiper-button-disabled {
    background: rgba(255, 255, 255, 0.031372549);
    border-color: rgba(255, 255, 255,1);
}

// slider home 01
.slider {
    .mark-page2 {
        bottom: 0;
        top   : 28.1%;
    }
    .mark-page3 {
        top: 12.6%
    }
    .mark-page4 {
        right: 8.7%;
        bottom: 22.1%;
    }
    .mark-page8 {
        bottom: 6.6%;
        left: 1.4%;
    }
    .mark-page9 {
        bottom: 0;
        left: 0;
    }
    .mark-slider-01 {
        margin-left: 39px;
    }
    .mark-slider-02 {
        top: 29.5%;
        right: 50.5%;
        z-index: -1;
    }
    .img-slider {
        border: 3px dashed var(--primary-color6);
        box-sizing: border-box;
        border-radius: 10px;
        margin-top: 57px;
        padding: 17px 16px 27px;
        z-index: 0;
        .bg-color {
            background: #fff;
            padding:  36px;
            border-radius: 10px; 
            margin-top   : -62px;
        }
        .box-img {
            padding: 0 10px;
            .img-1 {
                margin-right: 5px;  
            }
            .img-2 {
                margin-left: 5px;  
            }
            img {
                border-radius: 10px; 
            }
        }  
    }
}

.slider {
    // cursor:e-resize;
    position: relative;
    overflow: hidden;
    background: linear-gradient(79.57deg, rgba(19, 5, 24, 0.07) 10.75%, rgba(119, 89, 243, 0.07) 53.13%, rgba(19, 5, 24, 0.07) 106.55%);
    padding: 101px 0 284px;
    .wrap-heading {
        .content {
            width: 50.9%;
            margin-top: 23.2%;
            padding-left: 11.3%;
            h1 {
                cursor: text;
                text-transform: capitalize;
                line-height: 87px;
                margin-bottom: 7px;
                color: #7759F3;
                margin-right: -85px;
                .s1 {
                    background: linear-gradient(91.5deg, #7759F3 41.52%, #8E2AC3 96.58%);
                    color:  var(--primary-color);
                    -webkit-background-clip: text;
                    -webkit-text-stroke: 3px transparent; 
                    text-transform: capitalize;

                }
                .s2 {
                    text-transform: none;   
                    position: relative;
                    box-sizing: border-box;
                    margin-left: -1px;
                    background: #edeaf6;
                    background-clip: padding-box; 
                    border: solid 2x transparent; 
                    border-radius: 1em;
                    display: inline-table;
                    line-height: 54px;
                    padding-bottom: 6px;
                    &::before {
                        content: '';
                        position: absolute;
                        top: 0; right: 0; bottom: 0; left: 0;
                        z-index: -1;
                        margin: -2px; 
                        border-radius: inherit;
                        background: linear-gradient(180deg, #7759F3 0%, #3AFCC2 100%);
                    }
                }
            }
            p {
                cursor: text;
                font-size: 18px;
                font-family: "Inter", sans-serif;
                text-transform: capitalize;
                margin-left: 3px;
                margin-bottom: 41px;
                margin-right: -50px;
            }
            .sc-button {              
                &.style-1 {
                    margin-left: 3px;
                    margin-right: 18px;
                    padding: 19px 24px;
                }
                &.style-2 {
                    padding: 19px 29px;
                
                }
            }
        }
        // .image {
        //     width: 49.1%;
        // }
    }
}

.is_dark {
    .slider {
        .wrap-heading {
            .content {
                h1 {
                    color: #fff;
                    .s2 {
                        background: #130518;
                    }
                }
            }
        }
    }
}

// slider home 02
.slider {
    &.style-home2 {
        overflow: unset;
        padding: 139px 0 119px;
        .flat-wrap {
            padding: 27px;
            border: 3px dashed rgba(255, 255, 255, 0.07);
            border-radius: 10px;
            z-index: 5;
            .wrap-heading  {
                background-color: #AAADEC;
                border-radius: 10px;
                .content {
                    width: 50%;
                    margin-top: 9.5%;
                    padding-left: 9.7%; 
                    margin-bottom: 9.5%;  
                    position: relative;
                    z-index: 99999;
                    h1 {
                        margin-right: -98px;
                        color: #fff;
                        .s1 {
                            background: unset;
                            color: var(--primary-color3);
                        }
                        .s2 {
                            background: #AAADEC;
                        }   
                    }
                    p {
                        color: #706C83;
                    }
                    .sc-button {   
                        z-index: 99999;             
                        &.style-1 {
                            margin-left: 3px;
                            margin-right: 18px;
                            padding: 19px 24px;
                        }
                        &.style-2 {
                            padding: 19px 29px;
                        }
                    }          
                } 
                .image {
                    width: 50%;
                    text-align: center;
                    margin-top: 2.5%;
                }
            }
        }   
    }
}

.slider {
    &.style-home2 {
        .mark-page8 {
            bottom: -17.5%;
            left: 0.8%;
        }
        .mark-page2 {
            top: 34.5%;
            left: 8%;
            z-index: 9;
        }
        .mark-page3 {
            left: 37.9%;
            top: 23%;
            z-index: 9;
        }
        .mark-page4 {
            bottom: 9.5%;
        }
        .mark-page5 {
            z-index: 2;
        }
        .mark-page9 {
            bottom: -5%;
        }
        .mark-slider-02 {  
            top: 15%;
            right: 7.7%;
            z-index: 1;
        }
    }
}

// slider home 03
.slider {
    &.style-home3 {
        .flat-wrap {
            padding: unset;
            border: unset;
            .wrap-heading  {
                background-color: unset;
                justify-content: space-between;
                .content {
                    width: 47%;
                    margin-top: 11.1%;
                    padding-left: 11.3%; 
                    h1 {
                        margin-right: -144px;
                        color: #000;
                        .s2 {
                            background: #edeaf6;
                        }
                    }
                    p {
                        color: var(--primary-color4);
                        margin-right: -100px;
                    }
                }
                .img-slider {
                    margin-top: 0;
                    padding: 30px 30px 30px 47px;
                    border: unset;
                    .box-img {
                        background: #D9D1FA;
                        padding: 0 11px 0 25px;
                        margin-top: 0;
                        border-radius: 10px;  
                        position: relative;
                        .image {
                            width: auto;
                        margin-top: 0;
                            img {
                                margin-top: -13%;
                                margin-bottom: -13.2%;
                            }
                        } 
                        &::before {
                            content: "";
                            width: calc(100% + 80px);
                            height: calc(100% + 64px);
                            border: 3px dashed rgba(119, 89, 243, 0.16);
                            position: absolute;
                            top: 50%;
                            left: 48.5%;
                            transform: translate(-50%, -50%);
                        }                    
                    }
                }  
            }
        }
        .mark-page8 {
            bottom: -13%;
            left: -5.2%;
        }
        .mark-page5 {
            z-index: 0;
        }
    }
}

.is_dark {
    .slider {
        &.style-home3 {
            .flat-wrap {
                .wrap-heading  {
                    .content {
                        h1 {
                            color: #fff;
                            .s2 {
                                background: #0E1B3E;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* animation slider */
.flat-slider .flat-bt-slider,
.flat-slider .sub-heading,
.flat-slider .heading {
    transform: translateY(400px);
}

.mainslider.home .image{
    transform: translateX(400px);
}
.mainslider.home .image,
.flat-slider .flat-bt-slider,
.flat-slider .sub-heading,
.flat-slider .heading {
    opacity: 0;
}

.mainslider.home .swiper-slide-active .image,
.swiper-slide-active .flat-slider .flat-bt-slider,
.swiper-slide-active .flat-slider .sub-heading,
.swiper-slide-active .flat-slider .heading {
    opacity           : 1;
    visibility        : visible;
    -webkit-transition: transform 1000ms ease, opacity 1000ms ease;
    -moz-transition   : transform 1000ms ease, opacity 1000ms ease;
    -ms-transition    : transform 1000ms ease, opacity 1000ms ease;
    -o-transition     : transform 1000ms ease, opacity 1000ms ease;
    transition        : transform 1000ms ease, opacity 1000ms ease;
    transition-delay  : 1000ms;
}

.swiper-slide-active .flat-slider .flat-bt-slider,
.swiper-slide-active .flat-slider .sub-heading,
.swiper-slide-active .flat-slider .heading {
    transform: translateY(0) !important;
}

.mainslider.home .swiper-slide-active .image{
    transform: translateX(0px);
}

.mainslider.home .swiper-slide-active .image,
.swiper-slide-active .flat-slider .heading {
    transition-delay: 700ms !important;
}

.swiper-slide-active .flat-slider .sub-heading {
    transition-delay: 900ms;
}

.swiper-slide-active .flat-slider .flat-bt-slider {
    transition-delay: 1100ms;
}

/* Blog Page
-------------------------------------------------------------- */

.flat-blog-details {
    .post {
        .media {
            &.style {
                background: rgba(255, 255, 255, 0.03);
                border: 1px solid rgba(19, 5, 24, 0.07);
                box-sizing: border-box;
                padding: 20.8px 20px;
                border-radius: 5px;
                margin-bottom: 20px;
            }
        }
    }
}

.flat-blog-details {
    .post {
        h2 {
            font-weight: 500;
            margin-bottom: 16px;
        }
        .sc-card-article {
            margin-bottom: 40px;
            padding-bottom: 29px;
            border-bottom: 1px solid var(--primary-color6);
        }
        .text {
            margin-bottom: 40px;
        }
        .img-box {
            padding-left: 2px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 38px;
            ul {
                display: flex;
                margin-bottom: 29px;
                margin-top: -1px;
                &:last-child {
                    margin-bottom: 0;
                }
                li {
                    img {
                        margin-right: 19.5px;
                    }
                    h3 {
                        font-weight: 600;
                    }
                }
            }
        }
        .post-image {
            display         : flex;
            margin-bottom   : 42.2px;
            background-color: var(--primary-color10) ;
            padding         : 44px 0 37px 0;
            border-radius   : 5px;
            border: 1px solid  var(--primary-color6);
            .thumb {
                width        : 28.3%;
                padding-left: 49px;
                padding-top: 9px;
                position: relative;
                &::before {
                    content: '';
                    position: absolute;
                    width: 4px;
                    height: 90px;
                    left: 0;
                    top: 5px;
                    background-color: var(--primary-color3);
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }          
            }
            .title-author {
                margin-bottom: 17px;
                margin-left  : 2px;
                font-size: 18px;
                font-weight: 600;
                line-height: 30px;
            }
        }
        .text-1 {
            margin-bottom: 36px;
        }
        .text-boder {
            border-bottom: 1px solid var(--primary-color6);
            padding-bottom: 19px;
            margin-bottom: 30px;
        }
        .infor-row {
            justify-content: space-between;
            margin-bottom  : 46px;
            .tags {
                h4 {                 
                    margin-right: 19.5px;
                    margin-left: 0.5px;
                    font-weight: 600;   
                    align-self: center;             
                }
                div {
                    a {
                        padding         : 14px 18px 12px;
                        border-radius   : 5px;                      
                        font-size       : 12px;
                        font-weight     : 400;
                        margin-right    : 24px;
                        background-color: var(--primary-color10);
                        color           : var(--primary-color4);
                        border: 1px solid var(--primary-color6);
                        &.active,
                        &:hover {
                            background-color: var(--primary-color3);
                            color           : #fff;
                        }
                    }
                }
            }
        }
        .button {
            box-shadow: 0px 14px 14px rgba(95, 58, 252, 0.18);
        }
    }
}

#comments {
    .title-comment {
        margin-left: -1px;
        margin-bottom: 19px;
    }
    .comment-list {
        margin-bottom: 42px;
        .comment-avatar {
            width: 29%;
            padding-top: 3px;
        }
        .comment-content {
            .comment-text {
                border-bottom: 1px solid var(--primary-color6);
                padding-bottom: 20px;
                margin-bottom: 20px;
            }
            .comment-icon {
                font-size: 14px;
                font-weight: 400; 
                .icon-one {
                    margin-right: 42px ;
                    &::before {
                        content: '\f3e5';
                        font-family: "Font Awesome 5 Pro";
                        font-weight: 700;
                        margin-right: 6px;
                        margin-left: 2px;
                        font-size: 14px;
                    }
                }
                .icon-two {
                    &::before {
                        content: '\f004';
                        font-family: "Font Awesome 5 Pro";
                        font-weight: 700;
                        margin-right: 6px;
                        margin-left: 2px;
                        font-size: 14px;
                    }
                }   
            }
        }
    }
    .title-comment2 {
        margin-bottom: 21px;
    }
    .comment-form {
        fieldset {
            position     : relative;
            overflow     : hidden;
            width        : 100%;
            margin-bottom: 11px;
            &.name,
            &.email {
                float: left;
                width: 50%;
            }
            &.email {
                padding-left: 10px;
            }
            &.name {
                padding-right: 10px;
            }          
        }
        .style-text {
            width: 50%;
            float: left;
        }
        .phone-wrap,
        .name-wrap {
            padding-right: 15px;
        }
        .email-wrap,
        .site-wrap {
            padding-left: 15px;
        } 
        textarea  {
            padding: 7px 19px 17px ;
        }
        .text-call {
            text-transform: capitalize;
            margin-top: 21px;
            margin-bottom: 50px; 
            padding-right: 200px; 
            &::before {
                content: '*';
                color: #B14233;
                margin-left: 1px;
            }
        }
    }
}

// flat cetagories
 .flat-cetagories {
    &.style {
        padding-bottom: 89px;
    }
    .wrap-cetagories {
        display: flex;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        flex-wrap: wrap;
        margin-left: -30px;
        .cetagories {
            background: linear-gradient(80.47deg, rgba(19, 5, 24, 0.07) 2.16%, rgba(119, 89, 243, 0.1) 46.32%, rgba(19, 5, 24, 0.07) 97.43%);
            border-radius: 5px;
            width: calc(20% - 30px);
            margin-left: 30px;
            padding: 20px 27px 20px 30px;
            justify-content: space-between;
            margin-bottom: 30px;
            transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
            img {
                filter: brightness(0.9);
                // filter: drop-shadow(0px 0px 4px black);
            }
            
            &:hover {
                transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
                background:  var(--primary-color3);
                a,
                p {
                    color: #fff;
                    transition: all 0.35s cubic-bezier(0.4, 0, 0.2, 1);
                }
                img {
                    filter: drop-shadow(0px 0px 7px white);
                    transition: all 0.35s cubic-bezier(0.4, 0, 0.2, 1);
                }
            }
            .content {
               padding: 22px 0 0 0;
                h3 {
                    margin-bottom: 2px;
                    font-weight: 400;
                }
            }
        }
        .cetagories-button {
            margin: 34px 0 0 96px;
        }
    }
}

.is_dark {
    .wrap-cetagories {
        .cetagories {
            background: linear-gradient(80.47deg, rgba(255, 255, 255, 0.07) 2.16%, rgba(119, 89, 243, 0.1) 46.32%, rgba(255, 255, 255, 0.07) 97.43%);
            img {
                filter: unset;
            }
        }
    }
}

// flat cetagories home 2
.flat-cetagories2 {
    &.home2 {
        padding-bottom: 100px;
        background: #7759F312;
        .wrap-cetagories {
            .img-cetagories {
                border-right: 5px solid #fff;
                border-bottom: 5px solid #fff;
                border-top-left-radius: 10px;
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 8px;
                border-top-right-radius: 15px;
                transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
                img {
                    border-radius: 6px;
                    width: 100%;
                }  
            }
            .content {
                padding: 19px 0px 10px;
                .icon-cetagories {
                    margin-right: 15px;
                    margin-top: 9px;
                }
                h3 {
                    margin-bottom: 2px;
                    text-transform: capitalize;
                }
            }
            &:hover {
                .img-cetagories {
                    border-right: 5px solid var(--primary-color3);
                    border-bottom: 5px solid var(--primary-color3); 
                    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
                }
                .icon-cetagories {
                    transform: rotateY(360deg);
                    transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
                }
            }
        } 
    }
}

.is_dark {
    .flat-cetagories2 {
        &.home2 {
            .wrap-cetagories {
                .img-cetagories {
                    border-right: 5px solid #FFFFFF12;
                    border-bottom: 5px solid #FFFFFF12;
                }
                &:hover {
                    .img-cetagories {
                        border-right: 5px solid var(--primary-color3);
                        border-bottom: 5px solid var(--primary-color3); 
                        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
                    }
                }
            }
        }
    }
}

.flat-cetagories2 {
    &.home3 {
        background: unset;
        padding: 0;
        .style-container {
            background: var(--primary-color5);
            border: 1px solid var(--primary-color6);
            padding: 119px 0 100px;
            border-radius: 10px;
        }
    }
}

.sc-btn-button {
    .sc-button {
        padding: 19px 22px;
    }
}

// flat auctions
.flat-auctions {
    .sc-card-product {
        border: 1px solid  var(--primary-color6);
        padding: 19px 19px 0px;
        margin: 0;
        text-transform: capitalize;
        position: relative;
        .content {
            padding: 28px 10px ;
            .tags {
                position: absolute;
                top: 29px;
                left: 29px;
                font-size: 12px;
                font-weight: 500;
                border-radius: 5px;
                padding: 9px 8px;
                background-color: var(--primary-color3);
                color: #fff;
                transition: all 0.35s cubic-bezier(0.4, 0, 0.2, 1);
            }
            h3 {
                margin-bottom: 25px;             
            }
            .meta-price {
                border-bottom: 1px solid  var(--primary-color6);
                padding-bottom: 20px;
                margin-bottom: 19px;
                .price {
                    align-items: center;
                    img {
                        margin-right: 6px;
                    }               
                    .title-price {
                        font-weight: 400;
                        font-size: 16px;
                    }
                }
                .button-place-bid {
                    .sc-button {
                        padding: 5px 14px;
                        span {
                            font-size: 13px;
                        }
                    }
                }
            }
            .featured-countdown {
                .title-countdown {
                    margin-right: 5px;
                    color: var(--primary-color4);
                }
                .js-countdown {
                    color: var(--primary-color3);
                    .countdown__label {
                        margin: 0 4.3px;
                    }
                }
             }
        }     
    }  
}

// flat top seller
.flat-top-seller {
    background: #7759F312;
    padding-bottom: 117px;
    padding-top: 110px;
    .wrap-seller {
        background: var(--primary-color10);
        border: 1px solid var(--primary-color6);
        padding: 29px 0 29px ;
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        .img-box {
            margin-bottom: 18px; 
            position: relative;
            width: 110px;
            height: 110px;
            margin-left: auto;
            margin-right: auto;
            &::before {
                border-radius: 50%;
                // transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                width: 110px;
                height: 110px; 
                display: inline-block;
                border: 3px solid transparent;
                transform: rotate(45deg);  
                transition:
                      transform 0.4s linear 0s,
                      border-left-width 0s linear 0.35s; 
            }           
            .img-author img {
                border-radius: 50%;  
                position: relative;
            }
            .check {
                position: absolute;
                bottom: 5.5px;
                right: 8px;
                z-index: 9;
            }
        }
        .content {
            h3 {
                margin-bottom: 13px;
            }
            .price {
                align-items: center;
                justify-content: center;
                margin-bottom: 30px;
                img {
                    margin-right: 7px;
                }
            }
            .button-follow {
                a {
                    padding: 9.5px 34px;
                    span {
                        font-size: 13px;
                    }
                }
            }
        }
        &:hover {
            .img-box {
                &::before {
                    opacity: 1;
                    z-index: 9;
                    border-left-color: var(--primary-color3);
                    border-right-color: var(--primary-color3);
                    border-bottom-color: var(--primary-color3);
                    transition:
                        border-left-color 0.15s linear 0.2s, 
                        border-right-color 0.15s linear 0.1s,
                        border-bottom-color 0.15s linear 0.16s;
                }
            }
        }
    }
}


.flat-top-seller {
    &.home2 {
        padding: 0;
        background: unset;
        .style-container {
            background: rgba(119, 89, 243, 0.07);
            border-radius: 10px;
            padding-top: 178px;
            padding-bottom: 118px;
        }
        .wrap-seller {
            padding: 0 0 29px 18%;
            .img-box {
                margin-top: -61px;
                margin-left: 0;
                margin-right: 0;
            }
            .content {
                .price {
                    justify-content: unset;
                }
            }
        }
    }
    &.page {
        padding: 121px 0 120px;
        background: unset;
        .wrap-seller .img-box::before {
            top: -3px;
            left: -3px;
        }
    }
}

.is_dark {
    .flat-top-seller {
        &.home2 {
            .style-container {
                background: linear-gradient(79.57deg, #1B133C 10.75%, #130518 45.18%, #0E1B3E 106.55%);
            }
        }
    }
}

.flat-top-seller {
    &.home3 {
        background: unset;
        padding: 0;
        .style-container {
            padding: 110px 0 117px;
            background: var(--primary-color5);
            border: 1px solid var(--primary-color6); 
            border-radius: 0px;
        }
    }
}



// flat explore
.flat-explore {
    .sc-card-product {
        margin-bottom: 30px;
        .content {
            .meta-price {
                padding: 0;
                margin: 0 0 2px 0;
                border: 0;
            }
        }
        &.style {
            margin-bottom: 00px;
        }
    } 
    &.home2 {
        padding-bottom: 0;
    }  
}

// flat friendly
.flat-friendly {
    .wrap-friendly {
        background: url(./../../assets/images/backgroup-secsion/bg-friendly.jpg) center center no-repeat;
        border-radius: 5px;
        background-size: cover;
    }
        .box-img {
            margin: 13px 0 0px -3px;
        }
        ul {
            padding: 120px 0 113px;
            li {
                text-align: center;
                max-width: 18.1%;
                margin-right: 122px;
                width: 100%;
                position: relative;

                &:last-child {
                    margin: 0;
                    &::after {
                        display: none;
                    }
                }
                &::after {
                    content: '';
                    position: absolute;
                    right: -131px;
                    top: -19px;
                    width: 150px;
                    height: 90px;
                    background: url(./../../assets/images/icon/right.png) ;
                    background-repeat: no-repeat;
                    z-index: 999;
                }
                img {
                    margin-bottom: 25px;
                    transition: all 1s ease;
                }
                &:hover {
                    img {
                        transform: rotateY(180deg);
                    }
                }
                .title-friendly {
                    font-size: 24;
                    color: #fff;
                }
            }
        }
}

.flat-friendly {
    &.home2 {
        .wrap-friendly {
            background: url(./../../assets/images/backgroup-secsion/bg-user-friendly.jpg) center center no-repeat;
            background-size: cover;
        }
            .box-img {
                top: 13px;
                right: 0px;
                margin: 0;
            }
            ul {
                padding: 120px 0 113px 39px;
                li {
                    max-width: 22%;
                }
            }
            .img {
                top: 7px;
                right: 10px;
            }
        }
}

.flat-friendly {
    &.home3 {
        ul {
            border-bottom: 1px solid var(--primary-color6) ;
            padding: 122px 0 51px 46px;
            li {
                max-width: 15.85%;
                &::after {
                    filter: invert(1);
                }
                &:nth-child(2) {
                    &::after {
                        transform: rotateX(180deg);
                        top:0;
                    }
                }
            }

        }
    }
}

.is_dark {
    .flat-friendly {
        &.home3 {
            ul {
                li {
                    &::after {
                        filter: unset;
                    }
                }
            }
        }
    }
}

// flat collection
.flat-collection {
    padding: 100px 0 0;
    .flat-tabs {
        .content-img {
            width: 100%;
            margin-right: 20px;
        }
        .menu-img {
            width: 34.2%;
            li {
                margin-bottom: 9px;
                &:last-child {
                    margin: 0;
                }
            }
        }
    }
    .post {
        padding: 27px 10px 0;
        .text-article {
            margin-bottom: 25px;
        }
        .item-nft {
            .img-nft {
                margin-right: 4px;
                margin-top: -1px;
            }
        }
    }
    .sc-card-article.blog-article {
        border: 1px solid  var(--primary-color6);
        padding: 20px 19px 26px;
    }
   
}

.flat-collection {
    &.home2 {
        padding: 0 0 123px;
    }
    &.home3 {
        padding: 117px 0 122px;
    }
    &.page {
        padding: 120px 0;
        .sc-card-article.blog-article {
            margin: 0;
        }
    }
}

// flat brand 
.flat-brand {
    padding: 98px 0 97px;
    .brand-slider {
        padding: 0px 22px 0 37px;
        .slogan-logo {

            &:hover {
                filter: none;
            }
            a {
                img {
                    user-select: none;
                }
            }
        }
    }   
}

.flat-brand {
    &.home2 {
        padding: 67px 0 97px;
    }
}

// flat featured
.flat-featured {
    padding-bottom: 87px;
    .wrap-featured {
        .box-img {
            background: linear-gradient(180deg, rgba(95, 58, 252, 0.07) 0%, rgba(19, 5, 24, 0.07) 100%);
            padding: 15px;
            border-radius: 50%;
            width: 100px;
            margin: 0 auto; 
            img {
                transition: all 0.8s ease;
            }  
        }
        .content {
            padding: 31px 48px 20px 49px;
            h3 {
                margin-bottom: 20px;
            }
        }
        &:hover {
            img {
                transform: rotateY(360deg);
            }
        }
    }
}

.is_dark {
    .flat-featured {
        padding-bottom: 87px;
        .wrap-featured {
            .box-img {
                background: linear-gradient(180deg, rgba(95, 58, 252, 0.07) 0%, rgba(255, 255, 255, 0.07) 100%);
            }
        }
    }
}

.flat-featured {
    &.home3 {
        padding: 90px 0;
        .wrap-featured {
            .box-img {
                border: 1px dashed rgba(19, 5, 24, 0.17);
                background: unset;
            }
        }
    }
}

.is_dark {
    .flat-featured {
        &.home3 {
            .wrap-featured {
                .box-img {
                    border: 1px dashed rgba(255, 255, 255, 0.17);
                }
            }
        }
    }
}

// dropdown
.dropdown>button {
    outline: none;
    position             : relative;
    display              : inline-block;
    padding              : 8px 17px 7px 15px;
    min-width            : 142px;
    border-radius        : 6px;
    -moz-border-radius   : 6px;
    -webkit-border-radius: 6px;
    background           : #fff;
    font-size            : 14px;
    font-weight          : 400;
    line-height          : 24px;
    border: 1px solid var(--primary-color6);
    color                : var(--primary-color4);
    @include transition3;
    z-index              : 10;
    box-sizing: border-box;
    &::after {
        font-family      : 'Font Awesome 5 Pro';
        font-size        : 12px;
        font-weight      : 400;
        content          : "\f078";
        position         : absolute;
        color            : var(--primary-color3);
        right            : 13px;
        top              : 50%;
        @include transitionY;
        border: none;
        @include transition3;
    }
    &:hover {
        border: 1px solid var(--primary-color6);
    }
}

#item_category,
#view,
#new-items {
    border: none;
    outline: none;
    box-shadow: none;
    .dropdown {
        border: none;
        outline: none;
        box-shadow: none;
    }
    button {
        box-shadow: none;
        text-align: left;
        text-transform: capitalize;
    }
}

.dropdown {
    button {
        border: 1px solid var(--primary-color6) !important;
        color: #706C83 !important;
        background: #fff !important;
        &:hover {
            background: #fff;
            box-shadow: 0px 5px 4px rgb(28 24 24 / 5%);
        }
    }
}

.is_dark {
    background: #FFFFFF08;
    .dropdown {
        button {
            background: rgba(255, 255, 255, 0.03) !important;
            border: 1px solid rgba(255, 255, 255, 0.07) !important;
            &:hover {
                background: var(--primary-color3) !important;
                box-shadow: none;
                color: #fff !important;
                &::after {
                    color: #fff;
                }
            }
        }
    }
}

.btn-check:active+.btn-primary, .btn-check:checked+.btn-primary, .btn-primary.active, .btn-primary:active, .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background: rgba(255, 255, 255, 0.03);
    border-color: var(--primary-color6);
}

.seclect-box.style-2 .dropdown>button:after {
    right: 15px;
}


.dropdown a {
    border-top        : 0.5px solid rgba(122, 121, 138, 0.1);
    background        : #f8f8f8;
    width             : 100%;
    padding           : 9px 10px 8px 16px;
    list-style        : none;
    display           : block;
    margin            : 0;
    @include transition3;
}

.is_dark .dropdown a {
    background: #18133a;
}

.dropdown .dropdown-menu,
.dropdown a.active,
.dropdown .dropdown-menu,
.dropdown a:hover {
    background: var(--primary-color3);
}

.dropdown.style-2 .dropdown-menu {
    min-width: 100px;
}

.seclect-box {
    #view {
        margin-right: 15px;
    }
    #item_category {
        margin-right: 29px;
    }
    .title-item {
        width : 50.6%;  
        margin: auto 0;
    }
    .dropdown>button {
        min-width: 155px;
    }
}

.dropdown .dropdown-menu {
    padding: 0;
    border: none;
    z-index           : 10;
    position          : absolute;
    cursor            : pointer;
    width             : 100%;
    height            : auto;
    border-radius     : 0 0 6px 6px;
    z-index           : 1;
    -moz-box-shadow   : 0px 5px 4px rgba(28, 24, 24, 0.25);
    -webkit-box-shadow: 0px 5px 4px rgba(28, 24, 24, 0.25);
    box-shadow        : 0px 5px 4px rgba(28, 24, 24, 0.25);
    @include transitionY3;
    background: transparent
}

.dropdown .dropdown-menu,
.dropdown a {
    @include transition3;
    font-size         : 13px;
    line-height       : 20px;
    color             : var(--primary-color4);
    font-weight       : 400;
}

.is_dark .dropdown a {
    color: #fff;
}

.dropdown .dropdown-menu,
.dropdown abbr.active ,
.dropdown .dropdown-menu,
.dropdown a:hover  {
    color      : #fff;
    font-weight: 500;
    z-index: 9;
}

// page explore & auctions
.flat-auctions .search-form .search-field {
    background: var(--primary-color10);
    color: #fff;
    width: 100%;
    border: 1px solid var(--primary-color6);
    line-height: 20px;
    padding: 9px 50px 11px 24px;
    &:focus {
        color: var(--primary-color2);
        line-height: 20px;
        padding: 9px 50px 11px 24px;
    }
}

.flat-auctions {
    .search {
        .search-submit {
            top: 3px;
            right: 15px;
            color: var(--primary-color2);
            padding: 9px 14.5px;
            &:hover {
                background: unset;
                color: var(--primary-color3);
            }
            &.search-icon {
                &::after {
                    color: unset;
                }
            }
        }
    }
}

// live auctions details

.flat-auctions-details {
    padding-bottom: 0;
    margin-bottom: -4px;
    .wrap-img {
        .img-detail {
            background   : var(--primary-color10);
            border       : 1px solid var(--primary-color6);
            padding      : 20px;
            border-radius: 5px;
            margin-bottom: 28px;
            display      : inline-block;
            position     : relative;
            &:last-child {
                margin-bottom: 0;
            }
            h6 {
                position        : absolute;
                top             : 30px;
                left            : 30px;
                font-size       : 12px;
                font-weight     : 500;
                border-radius   : 5px;
                padding         : 9px 8px;
                background-color: var(--primary-color3);
                color           : #fff;
            }   
        }
    } 
    .post {
        h2 {
            margin-bottom: 16px;
        }
        .box-price {
            margin-bottom: 15px;
            .price {
                margin-right: 28px;
            }
            p {
                margin-right: 9px;
            }
            .star {
                align-self: center;
                i {
                    font-size: 9px;
                    margin-right: 0px;
                    color: #FCC65D;
                }
            }
        }
        .texts {
            margin-bottom: 19px;
        }
        .information {
            margin-bottom: 47px;
            .column {
                width: 50%;
                &.column-2 {
                    padding-left: 15px;
                }
            }
            span {
                color: var(--primary-color2);
            }
            .title-1 {
                margin-bottom: 16px;
            }
        }
        .featured-countdown {
            border-bottom: 1px solid var(--primary-color6);
            border-top   : 1px solid var(--primary-color6);
            padding      : 18px 0;
            margin-bottom: 29px;
            .title-countdown {
                margin-right: 5px;
                color       : var(--primary-color4);
                font-weight : 700;
            }
            .js-countdown {
                color      : var(--primary-color3);
                font-weight: 700;
                .countdown__label {
                    margin: 0 4.3px;
                }
            }
        }
        .button-place-bid {
            margin-bottom: 38px;
            a {
                padding: 19px 49px;
            }
        }
        .button-social {
            .sc-button {
                padding: 18px 33px 17px 31px;
                background: #5142FD;
                border: unset;
                margin-right: 8px;
                &.style-5:hover, 
                &.style-2:hover,
                &.style-3:hover,
                &.style-4:hover, 
                &:hover {
                    border: unset;
                    background: var(--primary-color3);
                    @include transition3;
                }
                span {
                    font-size: 14px;
                    font-weight: 400; 
                    line-height: 15px;
                }
                i {
                    font-size: 12px;
                    margin-right: 7px;
                    color: #fff;               
                }
                &.style-2 {
                    background: #E94235;
                    padding: 18px 39px 17px 36px;
                    i {
                        margin-right: 5px;
                        font-size: 11px;
                    }
                }
                &.style-3 {
                    background: #1C93E4;
                    padding: 18px 32px 17px 26px;
                    i {
                        margin-right: 5px;
                    }
                }
                &.style-4 {
                    background: #9342B3;
                    padding: 18px 29.7px 17px 23px;
                }
                &:last-child {
                    margin: 0;
                }
            }
        }
    }
}

.flat-price {
    .price {
        align-items: center;
        .icon-price {
            margin-right: 6px;
        }               
        .title-price {
            font-weight: 400;
            font-size: 16px;
        }
    }
}

.post {
    .react-tabs { 
        margin-bottom: 4px;
    }
}

.react-tabs {
    .menu-tab {
        display      : flex;
        align-items  : center;
        padding      : 7px 0;
        margin-bottom: 12px;
        li {
            position    : relative;
            line-height : 24px;
            margin-right: 42px;
            cursor      : pointer;
            &.react-tabs__tab--selected {
                color: var(--primary-color3);
            }
        }
    }
    .content {
        margin-bottom: 23px; 
        .meta-info {
            padding-bottom: 28.5px;
            border-bottom: 1px solid var(--primary-color6);
            .title-price {
                p {
                    line-height: unset;
                    margin-top : 6px;
                }
            }
        }  
    }
    .provenance {
        p {
            margin-bottom: 45px;
        }
    }
    li:last-child .content .meta-info {
        border: none;
    }
}   

// flat about
.flat-about {
    padding-bottom: 107px;
    .post {
        padding: 28px 0 28px 69px;
        .sub-title {
            margin-bottom: 20px;
            margin-left: 1px;
        }
        .title-about {
            font-size: 45px;
            line-height: 54px;
            margin-bottom: 12px;
        }
        .title-text {
            line-height: 30px;
            margin-bottom: 31px;
        }
        .text-1 {
            margin-bottom: 20px;
        }
        .box {
            padding-left: 1px;
            .img-1 {
                border-radius: 50%;
            }
            .img-2 {
                align-self: center;
                margin-left: 23px;
            }
        }
    }
}

/* count */
.flat-counter {
    padding: 0px 0 47px;
    .row-counter {
        padding-bottom: 42px;
        border-bottom: 1px solid var(--primary-color6) ;
    }
    .wrap {
        padding-left: 1px;
        padding-right: 70px;
        h2 {
            font-size: 52px;
            line-height: 70px;
        }
    }
    .counter-box {
        position      : relative;
        padding-top   : 24px;
        padding-left: 1px;
        p {
            line-height: 24px;
        }
        &.box-1 {
            padding-right: 80px;
        }
        .number {
            color        : var(--primary-color2);
            font-size    : 48px;
            font-weight  : 400;
            margin-bottom: 9px;
        }
    }
}

.flat-about2 {
    padding-bottom: 21px;
    .react-tabs {
        .menu-tab {
            margin-bottom: 8px;
            li {
                margin-right: 15.5px;
            }
        }
        .provenance {
            p {
                margin-bottom: 29px;
            }
        }
    }
}


// flat video
.flat-video {
    .post-video {
        position: relative;
        img {
            border-radius: 30px;
        }
        a {
            @include flex(center,center);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 200px;
            height: 200px;
            background: #fff;
            border-radius: 50%;
            text-align: center;
            line-height: 224px;
            &:hover {
                background: rgba(119, 89, 243, 1);
                span {
                    path {
                        fill:#fff;
                        @include transition3;
                    }
                }
            }
        }
    }
}

.ripple {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    -ms-border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    transform: translate(-50%, -50%);
    -ms-box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.849);
    -o-box-shadow: 0 0px 0 0px rgba(255, 255, 255, 0.883);
    box-shadow: 0px 0px 0px 30px rgb(255 255 255 / 10%);
    animation: pulse_three 3s linear infinite;
}

.flat-blog.page {
    padding-bottom: 0;
}

// flat author profile
.flat-author-profile {
    .tab-author {
        margin: 0;
        .author-profile{
            margin-top: 50px;
            background: url('./../../assets/images/mark/gallery_bg.png') no-repeat center;
            background-size: cover;
            border-radius: 5px;
            position: relative;
            padding: 51px 20px 59px 48px;
            justify-content:space-between;
            .feature-profile {
                margin-bottom: -109px;
                .img-box {
                    .avatar {
                        border-radius: 50%;
                    }
                    .check {
                        @include absolute-check;
                    }
                }             
                .infor {
                    padding-top: 56px;
                    padding-left: 30px;
                    h3 {
                        margin-bottom: 6px;
                    }
                }
            }
            .button-profile {
                align-items: center;
                padding-top: 58px;
                h3 {
                    margin-right: 20px;
                }
                a {
                    padding: 10px 34px;
                    span {
                        font-size: 13px;
                    }
                    &.style-1 {
                        margin-right: 20px;
                    }
                    &.style-2 {
                        padding: 10px 20px;
                    }
                }
            }
        }
        .tab-title {
            padding-left: 25.7%;
            padding-top: 31px;
            margin-bottom: 46px;
            li {
                margin-right: 48.5px;
            }
        }
        .content {
            margin-bottom: 0;
            .meta-info {
                border: unset;
                padding: 0;
            }
        }
        .react-tabs__tab-panel {
            @include flex2;
            flex-wrap: wrap;
            margin-left: -30px;
            .sc-card-product {
                width: calc(25% - 30px);
                margin-bottom: 30px;
                margin-left: 30px;
            }
        }

    }  
}

// flat edit profile
.flat-edit-profile {
    &.flat-auctions-details {
        padding-bottom: 122px;
        margin: 0;
    } 
    .author-profile {
        .feature-profile {
            position: relative;
            padding: 50px 29px 39px;
            border-radius: 5px;
            background: #FFFFFF08;
            border: 1px solid var(--primary-color6);
            .wrap-img {
                margin-bottom: 30px;
                .avatar {
                    border-radius: 50%;
                    width: 210px;
                    height: 210px
                }
                .check {
                    @include absolute-check;
                }
            }
        }
    }
    .form-upload-profile {
        padding-left: 100px;
        .title-one {
            margin-bottom: 27px;
        }
        .title-two {
            margin-bottom: 8px;
        }
        .title-three {
            margin-bottom: 8px;
        }
        .text-social {
            margin-bottom: 3px;
        }
        .option-profile {
            margin-bottom: 48px;
            form{
                width: 48%;
                margin-right: 30px;
            }
            .img-box {
                width: 48%;
                img {
                    height: 110px;
                    width: 100%;
                }
            }
        }
        .form-profile {
            .form-infor-profile {
                margin-bottom: 50px;
                .form-infor {
                    margin-left: -30px;
                }
                .info-social,
                .info-account {
                    width: calc(50% - 30px);
                    margin-left: 30px;
                }
            }
            .message {
                p {
                    margin-bottom: 4px;
                }
                textarea {
                    background: var(--primary-color10);  
                    padding: 9px 15px 17px 18.5px;
                }
            }
        }
        .box-button {
            .button-social {
                margin-bottom: 38px;
                .sc-button {
                    padding: 3px 0 0 0 !important;
                    border: unset;
                    margin-right: 29px;
                    margin-bottom: 21px;
                    width: 200px;
                    height: 50px;
                    line-height: 50px;
                    text-align: center;
                    &.style-2 {
                        margin-right: 27px;
                    }
                    &.style-5 {
                        background: #00A6E4;
                        &:hover {
                            background: var(--primary-color3);
                        }
                    }
                    i {
                        font-size: 14px;
                        margin-right: 6px;
                    }   
                }
            }
        }
        .tf-button-submit {
            width: 100%;
            padding: 19px;
            border: unset;
            box-shadow: 0px 14px 14px rgba(119, 89, 243, 0.15);
        }
    }
}

// flat create item
.flat-create-item {
    .form-upload-profile .form-profile .form-infor-profile {
        margin-bottom: 30px;
       
    }  
    .text-tag {
        margin-bottom: -3px;
    }
    .tab-create-item {
        margin-bottom: 30px;
        .tab-title {
            margin-bottom: 3px;
            .item-title:hover,
            .item-title.active {
            background-color: var(--primary-color3);
                .inner ,
                i {
                    color: #fff;
                }
            }
            li {
                width: 30.9%;
                background: var(--primary-color10);
                border: 1px solid var(--primary-color6);
                font-size: 14px;
                line-height: 19px;
                border-radius: 5px;
                display: flex;
                align-items: center;
                margin-right: 0;
                padding: 14.5px 0 14.5px 31px;
                .inner {
                    font-size: 14px;
                    color: var(--primary-color8);
                }
                i {
                    font-size: 12px;
                    margin-right: 8px;
                    color: var(--primary-color3);
                }
                svg {
                    margin-right: 8px;
                }
                &.react-tabs__tab--selected {
                    background-color: var(--primary-color3);
                    span {
                        color: #fff;
                    }
                    i {
                        color: #fff;
                    }
                    svg {
                        path {
                            fill: #fff;
                        }
                    }
                }
            }
        } 
        .info-title {
            width: 30.9%; 
            &.info-royalties {
                width: 100%;
            }
        }
        .dropdown > button {
            font-size: 12px;
            padding: 13px 18px 11px;
            min-width: 100%;
            text-align: left;
            box-shadow: none !important;
            outline: none;
            overflow: hidden;
            &::after {
                content: "\f107";
                right: 20px;
            }
        } 
    }  
    .button-social .sc-button.style-4 {
        padding: 18px 30px 17px 25px !important;
        i {
            font-size: 14px;
            margin-right: 5px;
        }
    } 
}

.flat-form {
    .title-infor-account {
        margin-bottom: 4px;
    }
    fieldset {
        margin-bottom: 11.2px;
        &.style {
            margin-bottom: -2px !important;
        }
        input {
            padding: 9px 15px 10px 18.5px;
            background: var(--primary-color10);  
            &::placeholder {
                font-size: 12px;
                font-family: 'Open Sans';
            }
        }
    } 
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    opacity: 0;
}

.number-wrapper {
    position: relative;
}
  
.number-wrapper:after,
.number-wrapper:before {
    position: absolute;
    right: 5px;
    width: 1.6em;
    height: .9em;
    font-size: 14px;
    pointer-events: none;
    background: transparent;
}
  
.number-wrapper:after {
    color: var(--primary-color3);
    content: "\f106";
    font-family: 'Font Awesome 5 Pro';
    top: 12px;
}
  
.number-wrapper:before {
    color: var(--primary-color3);
    content: "\f107";
    font-family: 'Font Awesome 5 Pro';
    bottom: 12px
}

// flat login
.flat-login {
    padding-bottom: 120px;
    margin: 0;
    .wrap-login {
        padding: 0 100px;
        .box-login {
            mix-blend-mode: normal;
            border: 1px solid var(--primary-color6);
            padding: 117px 100px 120px;
            background-color: var(--primary-color10);
            position: relative;
            z-index: 1;
            .mark-login {
                right: -4%;
                top: -4.5%;
                opacity: 0.35;
            }
            .mark-login1 {
                top: 8%;
                left: 3%;
                animation: move5 8s infinite linear;
            }
            .mark-login2 {
                right: 33%;
                top: 11.5%;
            }
            .mark-login3 {
                right: 7.5%;
                top: 23.5%;
            }
            .mark-page3 {
                right: 7% !important;
                top: 10.7% !important;
                left: unset;
                z-index: 5;
            }
            .heading-login {
                margin-bottom: 41px;
                a {
                    margin-left: 4px;
                }
                h2 {
                    margin-bottom: 16px;
                    margin-left: -1px;
                }
                &.register {
                    margin-bottom: 43px
                }
            }
            .form-login {
                position: relative;
                .info-login {
                    width: 48.2%;
                }
                .row-form {
                    margin-bottom: 43px;
                    label {
                        position: relative;
                        cursor: pointer;
                    }
                } 
                .button-login {
                    width: 100%;
                    padding: 18px;
                }
            }
            .box-button {
                width: 43.5%;
                text-align: end;
                padding-top: 33px;
                z-index: 10;
                position: relative;
                border-left: 1px solid var(--primary-color6);
                &::before {
                    content: 'OR';
                    position: absolute;
                    top: 49.6%;
                    left: -10px;
                    font-size: 14px;
                    font-weight: 600;
                    color: var(--primary-color4);
                }
                .sc-button {
                    width: 270px;
                    height: 50px;
                    margin-right: 0;
                    margin-bottom: 20px;
                    padding: 18px 10px 17px 31px;
                    text-align: left;
                    &.style-4 {
                        position: relative;
                        background: linear-gradient(268.96deg, #ED4C42 28.47%, #8841C0 73.65%);
                        overflow: hidden;
                        span,
                        i {
                            z-index: 2;
                            position: relative;
                        }
                        &::after {
                            background: linear-gradient(-268.96deg, #ED4C42 28.47%, #8841C0 73.65%);
                            opacity: 0;
                            content: "";
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            transition: 0.3s all ease-in-out;
                            z-index: 1;
                        }
                        &:hover {
                            &::after {
                                opacity: 1;
                            }
                        }
                    }
                }
            } 
        }
    }
}

.flat-login {
    .btn-checkbox {
        width: 16px;
        height: 16px;
        border: 1px solid var(--primary-color6);
        background-color: var(--primary-color5);
        border-radius: 4px;
        justify-content: center;
        margin-right: 10px;
    }
    .btn-checkbox:after {
        content: "\f00c";
        font-family: 'Font Awesome 5 Pro';
        color: #fff;
        display: none;
    }
    label input {
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        margin-right: 0px;
        margin-bottom: 0px;
    }
    label input:checked~.btn-checkbox {
        background-color: var(--primary-color3);
        border: 1px solid var(--primary-color3);
    }
    label input:checked~.btn-checkbox:after {
        display: block;
    }
    .style-pass {
        margin-bottom: 14px;
    } 
}

// flat register
.flat-register {
    .wrap-login {
        .box-login {
            .heading-login {
                margin-bottom: 42px;
            }
            .flat-form fieldset {
                margin-bottom: 12px;
            }
            .style-pass {
                margin-bottom: 14px !important;
            } 
            .mark-login {
                top: 0%;
            }
            .mark-login1 {
                top: 12.5%;
            }
            .mark-login2 {
                top: 13.5%;
            }
            .mark-page3 {
                right: 6% !important;
                top: 3.5% !important;
            }
        }
    }
}

// flat connect wallet
.flat-connect-wallet {
    padding-bottom: 90px;
    .wrap-connect {
        background-color: var(--primary-color10);
        border: 1px solid var(--primary-color6);
        padding: 50px 32px 38px;
        position: relative;
        overflow: hidden;
        margin-bottom: 30px;
        .image {
            margin-bottom: 28px;
            img {
                transition: all 0.8s ease;
            }
        }
        .content {
            h3 {
                margin-bottom: 19px;
            }
        } 
        &:hover img {
            transform: rotateY(360deg);
        }
        .popular {
            top: 17px;
            right: -25px;
            background-color: var(--primary-color3);
            width: 110px;
            transform: rotate(44deg);
            padding: 3px 0;
            @include transition3;
            display: none;
            &.active {
                display: block;
            }
            h3 {
                color: #fff;
            }
        }
    }
}

// flat faq
.flat-faq {
    .flat-tabs {
        .tab-title {
            display: unset;
            width  : 31.7%;
            padding-top: 1px;
            .item-title {
                width: 100%;
                line-height: 22px;
                padding: 18px 10px 18px 29px;
                margin-bottom: 39px;
                .inner {
                    font-size: 18px;
                    color: var(--primary-color2);
                }
            }
        }
        .content-tab {
            width: 57.5%;
            padding-top: 2px;
        }
    }
    .tab-create-item {
        margin-bottom: 0;
    }
    .flat-accordion {
        .flat-toggle {
            border-top: 1px solid var(--primary-color6);  
            &:last-child {
                border-bottom: 1px solid var(--primary-color6);
            } 
        }
        .btn-toggle {
            float: right;
            margin-top: -6px;
            &::after {
                content    : '\f107';
                font-family: "Font Awesome 5 Pro";
                font-weight: 500;
                color      : var(--primary-color4);
                font-size: 14px;
            }
        }
        .toggle-title.active {
            padding-top: 9px;
            color      : var(--primary-color3);
            margin-bottom: -2px;
            .btn-toggle {
                margin-top: -1px;
                &::after {
                    color      : var(--primary-color3); 
                }  
            }
        }
        .toggle-content {
            padding: 0px 18px 7px 20px;
        }
        h3 {
            padding: 14.25px 29px 14.25px 34px;
            cursor: pointer;
            line-height: 30px;
        }
    }
}

// flat map
.tf-map {
    padding-bottom: 106px;
    .box-adderss {
        background-color: var(--primary-color3);
        padding: 35px 82px 45px 31px;
        position: relative;
        margin: -70px 100px 0;
        border-radius: 5px;
        .icon {
            background: #FFFFFF08;
            border: 1px solid var(--primary-color6);
            padding: 14px 14px 13px;
            width: 60px;
            height: 60px;
        }
        .content {
            align-self: center;
            margin-left: 19px;
            margin-top: 1px;
            p {
                line-height: 22px;
            }
            a {
                color: #fff !important;
            }
           &.style {
                align-self: end;
                p {
                    margin-bottom: 8px;
                }
            } 
        }
    }
    .map-content {
        width: 100%;
        height: 770px;
    }
}

// flat contact
.flat-contact {
    padding-bottom: 120px;
    .heading {
        margin-bottom: 40px;
        h2 {
            margin-bottom: 9px;
        }
    }
    .form-upload-profile {
        padding: 0;
        .flat-form fieldset {
            margin-bottom: 10px;
            input {
                padding: 6px 15px 8px 19px;
                &::placeholder {
                    font-size: 14px;
                }
            }
        }
        .message textarea {
            padding: 7px 15px 17px 18.5px !important;
        }
        .form-infor-profile {
            margin-bottom: 60px !important;
        }
        button {
            box-shadow: 0px 14px 14px rgba(95, 58, 252, 0.18);
        }
    } 
    .wrap-contact {
        padding: 0 200px;
    }
}
  
.is_dark {
    .mode_switcher {
        h6 {
            color: #fff;
        }
    }
}

// /* switch mode */
.mode_switcher {
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    justify-content: center;
    align-items: center;
    z-index: 99999;
    h6 {
        line-height: 1.2;
        width: 85px;
        text-align: center;
        color: #343444;
        strong {
            font-size: 18px;
            font-weight: bold;
        }
    } 
    
    a {
        background: #343444;
        border-radius: 10px;
        width: 40px;
        height: 40px;
        min-width: 40px;
        font-size: 16px;
        margin-left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    
        i {
            margin-right: 5px;
        }
      }
  }
  .mode_switcher .light {
    background: linear-gradient(216.56deg, #E250E5 5.32%, #4B50E6 94.32%);
  }
  .is_dark .mode_switcher .dark{
    background: linear-gradient(216.56deg, #E250E5 5.32%, #4B50E6 94.32%);
  }
  .is_dark .mode_switcher .light {
    background: #343444;
  }

  .mainslider {
    &.home {
        .swiper-button-prev,
        .swiper-button-next {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background: var(--primary-color3);
            @include flex(center,center);
            @include transition3;
            box-shadow: 0px 3px 16px rgb(47 83 109 / 12%);
            &::after {
                color: #fff;
                @include transition3;
            }
            &:hover {
                background: #fff;
                &::after {
                    color: var(--primary-color3);
                }
            }
        }
    }
  }

  .countdown__timer {
    @include flex2;
    .countdown__item {
        position: relative;
        margin-right: 5px;
        padding-right: 8px;
        &:last-child {
            margin-right: 0;
            padding-right: 0;
            &::after {
                display: none;
            }
        }
        &::after {
            position: absolute;
            top: 0;
            right: 0;
            content: ":";
            color: var(--primary-color3);
        }
    }
  }


.brand-slider {
    .swiper-wrapper {
        align-items: center;
    }
}

.flat-title-page {
    &.style-home3 {
        .themes-container2 {
            width: 1550px;
        }
    }
}

.carousel-blog.show-shadow,
.carousel-6.show-shadow {
    padding-bottom: 40px !important;
    &.swiper-grid-column>.swiper-wrapper {
        flex-direction: inherit;
    }
    .swiper-slide {
        margin-top: 0 !important;
        margin-bottom: 30px;
    }
}

.carousel-blog.style-2 {
    padding-bottom: 43px !important;
    &.swiper-grid-column>.swiper-wrapper {
        flex-direction: inherit;
    }
    .swiper-slide {
        margin-top: 0 !important;
        margin-bottom: 30px;
    }
}

.themesflat-pagination {
    position: relative;
    z-index: 9;
}

.media {
    img {
        border-radius: 5px;
    }
}

.carousel-7.show-shadow {
    padding-bottom: 40px !important;
    &.swiper-grid-column>.swiper-wrapper {
        flex-direction: inherit;
    }
    .swiper-slide {
        margin-top: 0 !important;
        margin-bottom: 30px;
    }
}

.tf-section.flat-faq.flat-create-item {
    .flat-tabs {
        .react-tabs {
            @include flex(flex-start, space-between);
            width: 100%;
            .menu-tab {
                .react-tabs__tab {
                    width: 100%;
                    line-height: 22px;
                    padding: 18px 10px 18px 29px;
                    margin-bottom: 39px;
                    .inner {
                        color: #130518;
                        font-size: 18px;
                    }
                    &.react-tabs__tab--selected {
                        .inner {
                            color: #fff;
                        }
                    }
                }
            }
            .react-tabs__tab-panel {
                display: none;
                width: 57.5%;
                padding-top: 2px;
                &.react-tabs__tab-panel--selected {
                    display: block;
                }
            }
        }
    }
}

.accordion-item {
    border-top: 1px solid var(--primary-color6) !important;
    background: transparent;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    .accordion-button {
        position: relative;
        border: none;
        outline: none;
        box-shadow: none;
        font-size: 16px;
        cursor: pointer;
        line-height: 30px;
        font-weight: 400;
        color: var(--primary-color3);
        background: none;
        padding: 9px 29px 14px 34px;
        @include transition3;
        &.collapsed {
            color: #130518;
            padding: 14.25px 29px 13.25px 34px;
        }
        &::after {
            transform: rotate(0) !important;
            background: none;
            content: '\f107';
            font-family: "Font Awesome 5 Pro";
            font-weight: 400;
            font-size: 11px;
            width: auto;
            height: auto;
            position: absolute;
            top: 7px;
            right: 30px;
        }
    }
    &:last-child {
        border-bottom: 1px solid var(--primary-color6) !important;
        padding-bottom: 4px;
    }
    .accordion-body {
        margin-top: -12px;
    }
    margin-bottom: 1px;
    &:nth-child(1),
    &:nth-child(2) {
        margin-bottom: 0;
    }
}

.is_dark {
    .accordion-item {
        .accordion-button {
            color: #fff;
        }
    }
    .tf-section {
        &.flat-faq {
            &.flat-create-item {
                .flat-tabs {
                    .react-tabs {
                        .menu-tab {
                            .react-tabs__tab {
                                .inner {
                                    color: #fff;
                                    font-size: 18px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.flat-title-page.style2 {
    .themes-container2 {
        width: 1550px;
    }
}

footer {
    &.home2 {
        .col-lg-4 ,
        .col-lg-2 {
            z-index: 1;
        }
    }
}

.widget-social-footer {
    &.home2 {
        .text-color-1 {
            color: #706C83;
        }
    }
}

.is_dark {
    .widget-social-footer {
        &.home2 {
            .text-color-1 {
                color: #fff;
            }
        }
    }
}