@use './../../scss/' as *;

.base {
    min-width: 100%;
    height: 100%;
    background: white;
    border-radius: 20px;

    padding: 2rem;
    padding-top: 2.5rem;

    &.qna-visible {
        height: 500px;
        @media screen and (max-width: 768px) {
            height: 60vh;
            
        }
    }

}