
@use '../' as *;
.btn-collection {
    
    /* swiper button */
    .swiper-button-next::after {
        content: "\f178";
    }
    .swiper-button-prev::after,
    .swiper-button-next::after {
        @include transition3;
    }
    .swiper-button-prev::after {
        content: "\f177";
    }

    .swiper-button-prev,
    .swiper-button-next {
        &.swiper-button-disabled {
            background: #fff;
            &::after {
                color: var(--primary-color3);
            }
        }
    }

    .is_dark .swiper-button-prev.active,
    .is_dark .swiper-button-next.active,
    .is_dark .swiper-button-next:hover,
    .is_dark .swiper-button-prev:hover {
        background: var(--primary-color3);
        border-color: var(--primary-color3);
    }

    .swiper-button-next,
    .swiper-button-prev{
        width: 80px;
        height: 40px;
        border-radius: 5px;
        box-shadow: 0px 3px 16px rgb(47 83 109 / 12%);
        -webkit-transition: all 0.3s ease-in-out;
        position: absolute;
        top: 30px;
        background: var(--primary-color3);
        border: 1px solid var(--primary-color3);
        &::after {
            color: #fff;
        }
        &.swiper-button-disabled {
            opacity: 1;
            background: rgba(255, 255, 255, 0.03);
            border: 1px solid rgba(255, 255, 255, 0.07);
            &::after {
                // color: rgb(255 255 255 / 30%);
                color: var(--primary-color3);
            }
        }
        &:hover {
            background: transparent;
            &::after {
                color: var(--primary-color3);
            }
        }
    }

    .swiper-button-prev{
        right: 100px;
        left: auto;
    }
    .swiper-button-next {
        right: 0;
    }

    .swiper-button-next::after, 
    .swiper-button-prev::after {
        font-family: "Font Awesome 5 Pro";
        font-weight: 300;
        font-size: 28px;
        opacity: 1;
    }
    &.style2 {
       .swiper-button-prev {
        right: 100px;
       }
    }
}

.is_dark .btn-collection {
    .swiper-button-next,
    .swiper-button-prev {
        background: var(--primary-color3);
        border-color:  var(--primary-color3);
        &.swiper-button-disabled {
            background: rgba(255, 255, 255, 0.03);
            border: 1px solid rgba(255, 255, 255, 0.07);
            &::after {
                color: rgb(255 255 255 / 30%);
            }
        }
        &:hover {
            background: #fff;
            border-color: #fff;
            &::after {
                color: var(--primary-color3);
            }
        }
    }
}

.btn-collection2 {
    /* swiper button */
    .swiper-button-next::after {
        content: "\f178";
    }
    .swiper-button-prev::after,
    .swiper-button-next::after {
        @include transition3;
    }
    .swiper-button-prev::after {
        content: "\f177";
    }

    .swiper-button-prev,
    .swiper-button-next {
        &.swiper-button-disabled {
            background: #fff;
            &::after {
                color: var(--primary-color3);
            }
        }
    }

    .is_dark .swiper-button-prev.active,
    .is_dark .swiper-button-next.active,
    .is_dark .swiper-button-next:hover,
    .is_dark .swiper-button-prev:hover {
        background: var(--primary-color3);
        border-color: var(--primary-color3);
    }

    .swiper-button-next,
    .swiper-button-prev{
        width: 80px;
        height: 40px;
        border-radius: 5px;
        box-shadow: 0px 3px 16px rgb(47 83 109 / 12%);
        -webkit-transition: all 0.3s ease-in-out;
        position: absolute;
        bottom: 0;
        top: auto;
        background: var(--primary-color3);
        border: 1px solid var(--primary-color3);
        &::after {
            color: #fff;
        }
        &.swiper-button-disabled {
            opacity: 1;
            background: rgba(255, 255, 255, 0.03);
            border: 1px solid rgba(255, 255, 255, 0.07);
            &::after {
                // color: rgb(255 255 255 / 30%);
                color: var(--primary-color3);
            }
        }
    }

    .swiper-button-prev{
        right: 115px;
        left: auto;
    }
    .swiper-button-next {
        right: 15px;
    }

    .swiper-button-next::after, 
    .swiper-button-prev::after {
        font-family: "Font Awesome 5 Pro";
        font-weight: 300;
        font-size: 28px;
        opacity: 1;
    }
    &.style2 {
       .swiper-button-prev {
        right: 100px;
       }
    }
}

.is_dark .btn-collection {
    .swiper-button-next,
    .swiper-button-prev {
        background: var(--primary-color3);
        border-color:  var(--primary-color3);
        &.swiper-button-disabled {
            background: rgba(255, 255, 255, 0.03);
            border: 1px solid rgba(255, 255, 255, 0.07);
            &::after {
                color: rgb(255 255 255 / 30%);
            }
        }
    }
}

.carousel-blog {
    &.btn-collection2 {
        .swiper-button-next {
            right: 0;
        }
        .swiper-button-prev {
            right: 100px;
        }
    }
}
